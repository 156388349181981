import { Canvas } from "@react-three/fiber";
import { useGLTF, OrbitControls, ScrollControls, Stars, Html } from '@react-three/drei';
import './App.css';
import { Header } from './components';

function Model(props) {
  const { scene } = useGLTF("./models/slave1.glb");
  return <primitive object={scene} {...props} />
}

const App = () => {
  return (
    <div className='App'>
      
      <Canvas >
        <Html>
          <Header />
        </Html>
        <OrbitControls enableZoom={false} enableRotate={false} enablePan={false} />
        {/* <ScrollControls pages={3} damping={0.25}> */}
          {/* <pointLight color="#f6f3ea" position={[2, 0, 5]} intensity={20} />
          <hemisphereLight args={['#fff', '#333', 4]} /> */}
          <Stars
            radius={300}
            depth={60}
            count={20000}
            factor={7}
            saturation={0}
            fade={true}
          />

            {/* <Model scale={0.01} /> */}


          

        {/* </ScrollControls> */}
          
      </Canvas>

    </div>
  );
}

export default App;
