import React from 'react';
import './header.css';
const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className='gpt3__header-content'>
        <h1 className='gradient__text'>Updating, please check back later</h1>
      </div>
      <div className='gpt3__header-image'>

      </div>
    </div>
  )
}

export default Header